<template>
  <form @submit.prevent="handleSubmit" class="form" >
    <Loader class="form-loader" v-if="submitting" />
    <div class="field">
      <p v-html="timeUp ? submitPageContent.trContestStartedMessage : submitPageContent.trAboveEmailLabel"></p>
    </div>
    <div class="email-box">
      <div class="input-box">
        <input class="input" :class="[emailValidationClasses, {'is-empty': email.email.length === 0}]" v-model="email.email" @keyup="isEmailValid" type="email" placeholder="E-Mail" @focus="addDataLayer">
        <img class="email-pic" src="@/assets/email.png" alt="mail-logo">
        <img v-if="email.correct && email.email.length > 0" class="input-status-img" src="@/assets/tick.png" alt="tick-sign">
        <img v-if="!email.correct && email.email.length > 0" class="input-status-img" src="@/assets/danger.png" alt="danger-sign">
      </div>
      <div class="control-button">
        <button :class="{ disabled: !email.correct || email.email.length === 0 || submitting }" class="button confirm-btn">{{ submitPageContent.trConfirmEmailBtn }}</button>
      </div>
    </div>
    <div v-if="!email.correct && email.email.length > 0 && !emailExists" class="email-feedback">{{ submitPageContent.trInvalidEmailError }}</div>
    <div v-if="emailExists" class="email-feedback">{{ submitPageContent.trUserEntryExists }}</div>
    <div class="checkboxes">
      <div class="checkbox">
        <input v-model="checkboxes" value="terms" type="checkbox" required>
        <router-link :to="{ name: 'Terms' }">{{ submitPageContent.trTerms }}</router-link>
        &nbsp;
        <router-link :to="{ name: 'Privacy' }">{{ submitPageContent.trPersonalDataProcessing }}</router-link>
      </div>
      <div class="checkbox">
        <input v-model="checkboxes" value="newsletter" type="checkbox">
        {{ submitPageContent.trNewsletter }}
      </div>
      <div class="checkbox">
        <input v-model="checkboxes" value="age" type="checkbox" required>
        {{ submitPageContent.trAgeVerification }}
      </div>
      <!-- <div class="checkbox">
        <input v-model="checkboxes" value="privacy" type="checkbox" required>
        <router-link :to="{ name: 'Privacy' }">{{ submitPageContent.trPersonalDataProcessing }}</router-link>
      </div> -->
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EmailEntry',
  data() {
    return {
      email: {
        email: '',
        correct: false,
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      },
      checkboxes: [],
      submitting: false,
      emailExists: false,
      focusedOnce: false,
    };
  },
  props: ['timeUp'],
  methods: {
    addDataLayer() {
      if (this.focusedOnce) return;
      this.focusedOnce = true;
      window.dataLayer.push({
        event: 'Form step',
        stepNo: 'step 3',
        stepName: 'Form input started',
      });
    },
    handleSubmit() {
      if (!this.email.correct || this.submitting) return;
      window.dataLayer.push({
        event: 'Form step',
        stepNo: 'step 4',
        stepName: 'Confirm email',
      });
      this.emailExists = false;
      this.submitting = true;
      const payload = {
        userPicks: this.$store.state.userPicks,
        email: this.email.email,
        geo: this.$store.state.options.cc,
        sportType: this.$store.state.options.sportType,
        queryParams: this.$store.state.queryParams,
        newsletter: this.checkboxes.includes('newsletter'),
      };
      // console.log(payload);
      // console.log(window.sessionStorage);
      this.emailSubmit(payload);
    },
    isEmailValid() {
      if (this.emailExists) {
        this.emailExists = false;
      }
      this.email.correct = this.email.reg.test(this.email.email);
    },
    async emailSubmit(payload) {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const response = await this.axios.post(`${process.env.VUE_APP_API}/submit/v1/entry`, payload, config);
        // console.log(payload);
        this.submitting = false;
        if (response.data === 0) {
          this.emailExists = true;
          return;
        }
        if (response.data === 1) {
          // Pushing to Thank You Page
          this.$router.push({ path: this.urlPath });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    emailValidationClasses() {
      return this.email.correct ? 'is-success' : 'is-error';
    },
    ...mapGetters([
      'emailCheckboxes',
      'submitPageContent',
      'routePath',
    ]),
    urlPath() {
      return `${this.routePath.sportTypeSlug}/${this.routePath.currentGeo}/thank-you`;
    },
  },
  created() {
    this.checkboxes = [...this.emailCheckboxes];
  },
};
</script>

<style lang="scss">
.form {
  position: relative;
  padding: .5rem 1rem;
}
.field p {
  font-size: 14px;
  text-align: center;
  color: #4a4a4a;
}
.email-box {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0.5rem 0 .5rem;
}
.input-box {
  display: flex;
  position: relative;
  flex: 1;
  .email-pic {
    position: absolute;
    top: 50%;
    left: .5rem;
    transform: translateY(-50%);
    width: 15px;
  }
  .input-status-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .2rem;
    width: 14px;
    margin-right: .5rem;
  }
  input {
    flex: 1;
    padding: .5rem 2rem .5rem 2rem;
    border-radius: 4px;
    border: 1px solid;
  }
  input ~ img {
    opacity: .25;
  }
  input:focus ~ img {
    opacity: .5;
  }
}
.control-button {
  margin-top: .5rem;
  text-align: center;
}
.is-success {
   outline-color: #32aa45;
}
.is-error:not(.is-empty) {
  outline-color: #ff3860;
}
.email-feedback {
  color: #ff3860;
  font-size: 14px;
  text-align: center;
  margin: .2rem 0;
}
.checkboxes {
  margin-top: .5rem;
}
.checkbox {
  text-align: center;
  color: #868686;
  font-size: 14px;
  a {
    text-decoration: none!important;
    color: #3273dc;
  }
  input {
    margin-right: .2rem;
  }
  &:not(:last-child) {
    margin-bottom: .3rem;
  }
}
.form-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  background-color: rgb(255, 255, 255, .75);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
