<template>
  <div v-if="!timeReached" class="countdown">
    <div class="countdown-timer">
      <div class="countdown-box">
        <div class="countdown-top">{{ time.days }}</div>
        <div class="countdown-bottom">{{ timerTranslation.trDays }}</div>
      </div>
      <div class="countdown-box">
        <div class="countdown-top">{{ time.hours }}</div>
        <div class="countdown-bottom">{{ timerTranslation.trHours }}</div>
      </div>
      <div class="countdown-box">
        <div class="countdown-top">{{ time.minutes }}</div>
        <div class="countdown-bottom">{{ timerTranslation.trMinutes }}</div>
      </div>
      <div class="countdown-box">
        <div class="countdown-top">{{ time.seconds }}</div>
        <div class="countdown-bottom">{{ timerTranslation.trSeconds }}</div>
      </div>
    </div>
    <div class="time-remaining-txt">{{ timerLabel }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Timer',
  data() {
    return {
      time: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        diffTime: 0,
      },
      polling: null,
    };
  },
  methods: {
    timeCalc() {
      const finalTime = this.$route.name === 'YourPicks' ? this.countdownTimer.endTime : this.countdownTimer.startTime;
      const startTime = moment(finalTime * 1000);
      const current = moment();
      // Get time difference
      this.time.diffTime = startTime.diff(current);
      if (this.time.diffTime <= 0) return;
      // Calc duration
      let duration = moment.duration(this.time.diffTime);
      // Set time
      this.time.days = duration.days();
      this.time.hours = duration.hours();
      this.time.minutes = duration.minutes();
      this.time.seconds = duration.seconds();
      // Start countdown
      this.polling = setInterval(() => {
        this.time.diffTime -= 1000;
        duration = moment.duration(this.time.diffTime);
        this.time.days = duration.days();
        this.time.hours = duration.hours();
        this.time.minutes = duration.minutes();
        this.time.seconds = duration.seconds();
      }, 1000);
    },
  },
  mounted() {
    this.timeCalc();
  },
  unmounted() {
    clearInterval(this.polling);
  },
  computed: {
    ...mapGetters([
      'timerTranslation',
      'countdownTimer',
    ]),
    timeReached() {
      return this.time.diffTime <= 0;
    },
    diffTime() {
      return this.time.diffTime;
    },
    timerLabel() {
      return this.$route.name === 'YourPicks' ? this.timerTranslation.trTimeToResult : this.timerTranslation.trTimerLabel;
    },
  },
  watch: {
    diffTime(newValue) {
      this.$emit('time', newValue);
    },
  },

};
</script>

<style lang="scss">
.countdown {
   background: #0e1b38;
   padding: .5rem 0;
}
.countdown-timer {
  display: flex;
}
.countdown-box {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  color: #fff;
  font-size: 11px;
  line-height: 1.2;
}
.countdown-box:not(:last-child) {
  border-right: 1px solid #fff;
}
.countdown-top {
  font-size: 1.8rem;
  font-weight: 700;
}
.time-remaining-txt {
  font-weight: 500;
  line-height: 1.5;
  font-size: .85rem;
  text-align: center;
  color: #fff;
  white-space: nowrap;
  margin-top: 1rem;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    border-top: 1px solid #fff;
    width: 30%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
