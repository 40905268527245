<template>
  <Header />
  <Timer />
  <MainBanner />
  <EmailEntry />
  <EmailSubOverlay v-if="emailSubmissionOverlay.enableOverlay && showOverlay" v-on:close="showOverlay = false" :emailSubOverlayData="emailSubmissionOverlay" />
</template>

<script>
import { mapGetters } from 'vuex';

import MainBanner from '@/components/MainBanner.vue';
import Timer from '@/components/Timer.vue';
import EmailEntry from '@/components/EmailEntry.vue';
import EmailSubOverlay from '@/components/EmailSubOverlay.vue';

export default {
  name: 'Submit',
  components: {
    Timer,
    MainBanner,
    EmailEntry,
    EmailSubOverlay,
  },
  data() {
    return {
      showOverlay: true,
    };
  },
  methods: {
    userPicksExist() {
      const userPicks = this.$store.state.userPicks.picks;
      if (userPicks === undefined) {
        this.$router.push({ path: `${this.routePath.sportTypeSlug}/${this.routePath.currentGeo}` });
      }
    },
  },
  created() {
    this.userPicksExist();
  },
  computed: {
    ...mapGetters([
      'routePath',
      'emailSubmissionOverlay',
    ]),
  },
};
</script>
