<template>
  <div class="modal">
    <div class="modal-body">
        <div @click="$emit('close')" class="close-modal">X</div>
        <a :href="emailSubOverlayData.overlayBannerClickout" target="_blank" rel="noindex nofollow noopener noreferrer">
          <img :src="emailSubOverlayData.overlayBanner" alt="">
        </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailSubmissionOverlay',
  props: ['emailSubOverlayData'],
};
</script>

<style lang="scss">
  .modal {
    position: fixed;
    padding: .5rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9999;
    font-size: 0.85em;
  }
  .modal-body {
    position: relative;
    max-width: 100%;
    width: 500px;
    text-align: center;
  }
  .close-modal {
    cursor: pointer;
    font-weight: 600;
    position: absolute;
    right: -.25rem;
    top: -.75rem;
    width: 20px;
    height: 20px;
    background-color: #262626;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #fff;
  }
</style>
