<template>
  <div class="main-banner">
    <img :src="this.$store.state.options.generalSettings.mainBanner">
  </div>
</template>

<script>
export default {
  name: 'MainBanner',
};
</script>

<style lang="scss">
.main-banner {
  border-bottom: 5px solid #0e1b38;
  img {
    display: block;
  }
}
</style>
